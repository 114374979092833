import { defineStore } from 'pinia'

const state = () => ({
  menu: false,
  menu2: false,
  desktopNavMenu: false
})

// functionName: (state, getters, rootState) => {}
const getters = {
}

// functionName ({ commit, state, dispatch }, param, ...) {}
const actions = {
  openMenu() {
    if (this.menu) return;
    this.menu = true
    document.documentElement.classList.add("lock");
    document.body.classList.add("lock", "CollapseMenuMobile");
  },
  openMenu2() {
    if (this.menu2) return;
    this.menu2 = true
    document.documentElement.classList.add("lock");
    document.body.classList.add("lock", "CollapseMenuMobile2");
  },
  openDesktopNavMenu() {
    if (this.desktopNavMenu) return;
    this.desktopNavMenu = true
    document.documentElement.classList.add("lock");
    document.body.classList.add("lock", "CollapseDesktopNavMenu");
  },

  closeMenu() {
    if (!this.menu) return;
    this.menu = false
    document.documentElement.classList.remove("lock");
    document.body.classList.remove("lock", "CollapseMenuMobile");
  },
  closeMenu2() {
    if (!this.menu2) return;
    this.menu2 = false
    document.documentElement.classList.remove("lock");
    document.body.classList.remove("lock", "CollapseMenuMobile2");
  },
  closeDesktopNavMenu() {
    if (!this.desktopNavMenu) return;
    this.desktopNavMenu = false
    document.documentElement.classList.remove("lock");
    document.body.classList.remove("lock", "CollapseDesktopNavMenu");
  }
}

export default defineStore({
  id: 'bcMenu',
  state,
  getters,
  actions
})
